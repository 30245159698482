import * as React from 'react';
import { graphql } from 'gatsby';

import { Template } from 'common/layout';
import { PageTransition } from 'common/layout/PageTransition';

import { PaymentSuccessPage } from 'modules/PaymentSuccessPage';

const PaymentSuccess = () => {
  return (
    <Template>
      <PageTransition>
        <PaymentSuccessPage />
      </PageTransition>
    </Template>
  );
};

export const query = graphql`
  query PaymentSuccess($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          language
          data
        }
      }
    }
  }
`;

export default PaymentSuccess;
