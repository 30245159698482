import * as i from 'types';
import * as React from 'react';
import { useLocation } from '@reach/router';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';

import { meDataSelector } from 'selectors/me';
import { api } from 'services/api';
import { useDispatch, useSelector, useAuthenticationUser } from 'services/hooks';
import { generate3DSecureAuthUrl, getPageQueryParams, triggerGtagEvent } from 'services';
import { completeOrder, getOrderById, getPaymentInfo, verifyPaymentIntent } from 'ducks/orders';
import { getMeSeries, getMeContracts } from 'ducks/meSeries';

import { Text, Heading } from 'common/typography';

import {
  PageWrapper, StyledButton, HeadingContainer, ButtonContainer,
  StyledArrow, Header, Container,
} from './styled';

export const PaymentSuccessPage = () => {
  const { t } = useTranslation();
  const { navigate } = useI18next();
  const [paymentError, setPaymentError] = React.useState<boolean>(false);
  const [paymentComplete, setPaymentComplete] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const userData = useSelector(meDataSelector);
  const params = getPageQueryParams(location);
  const { determined, authenticated } = useAuthenticationUser();

  const isOrderSuccessful = (order: i.Order) => {
    if (!order) return false;

    const noAmountDue = order.amountDue.amount === 0;
    const isOrderClosed = order.orderStatus === 'Closed';
    const hasSuccessfulTransaction = order.transactions?.some((item) => item.successful);

    return noAmountDue && hasSuccessfulTransaction && isOrderClosed;
  };

  React.useEffect(() => {
    if (determined && userData && authenticated) {
      dispatch(getOrderById(params.orderId))
        .then((order) => {
          if (params?.paymentMethod === 'creditcard') {
            handleCreditCard(order);
          } else if (params?.paymentMethod === 'ideal') {
            handleIdeal(order);
          } else {
            handleSepa();
          }
        })
        .catch(paymentFailed);
    }
  }, [determined, userData]);

  const paymentSuccessful = (order?: i.Order) => {
    // Refetch series and contracts of user, to show most up to date balance
    dispatch(getMeSeries());
    dispatch(getMeContracts());
    setPaymentComplete(true);

    // JSON Template for GTAG
    // Google default payload, unused fields disabled
    const eventPayload = {
      transaction_id: order?.transactions[0]?.id,
      value: order?.subtotal?.amount,
      tax: order?.tax?.amount,
      // shipping: 'shipping',
      currency: order?.total?.currency,
      // coupon: 'coupon',
      items: [
        {
          item_id: order?.seriesOrderItems[0]?.seriesId,
          item_name: order?.seriesOrderItems[0]?.series,
          // affiliation: 'affiliation',
          // coupon: 'coupon',
          discount: order?.seriesOrderItems[0]?.discount.amount,
          index: 0,
          // item_brand: 'item_brand',
          item_category: order?.seriesOrderItems[0]?.seriesType,
          // item_list_id: 'item_list_id',
          // item_list_name: 'item_list_name',
          // item_variant: 'item_variant',
          // location_id: 'location_id',
          price: order?.seriesOrderItems[0]?.price.amount,
          quantity: 1,
        }],
    };
    triggerGtagEvent('purchase', eventPayload);
  };

  const paymentFailed = () => {
    setPaymentError(true);
    setPaymentComplete(true);
  };

  const handleSepa = () => {
    api.post({
      path: `/proxy/zingfit/payments/${params?.orderId}/sepa`,
      body: { sourceId: params.token },
    })
      .then(paymentSuccessful)
      .catch(paymentFailed);
  };

  const handleIdeal = (order: i.Order) => {
    if (isOrderSuccessful(order)) {
      paymentSuccessful(order);
    } else {
      paymentFailed();
    }
  };

  const handleCreditCard = async (order: i.Order) => {
    /**
    * If validatePaymentIntent is true the payment intent (token) received by stripe through the
    * 3D secure authentication, needs to be verified
    */
    if (!params.validatePaymentIntent) {
      const orderResponse = await dispatch(completeOrder(order, params?.token));

      // Check if 3D Secure authentication is required to complete creditcard order
      if (orderResponse.requiresAction) {
        const paymentInfo = await dispatch(getPaymentInfo(order?.id));
        const newUrl = generate3DSecureAuthUrl({
          order,
          paymentInfo,
          paymentIntentClientSecret: orderResponse.clientSecret,
          language: 'en',
        });

        // Set 3D Secure authentication url to WebView
        if (newUrl) window.location.href = newUrl;
      }
    } else {
      // Verify payment intent (token) received when the 3D Secure authentication was successful
      const orderResponse = await dispatch(verifyPaymentIntent(order?.id, params.token));

      if (orderResponse.success) {
        paymentSuccessful();
      } else {
        paymentFailed();
      }
    }
  };

  return (
    <PageWrapper>
      <Container>
        <Header>
          <StyledArrow />
        </Header>
        {!paymentComplete ? (
          <>
            <HeadingContainer>
              <Heading as="h2">{t('payment pending')}</Heading>
              <Text>{t('payment is still pending')}</Text>
            </HeadingContainer>
            <ButtonContainer>
              <StyledButton
                isLoading={!paymentComplete}
                disabled={!paymentComplete}
              >
                {t('book class')}
              </StyledButton>
            </ButtonContainer>
          </>
        ) : (
          <>
            <HeadingContainer>
              {!paymentError ? (
                <>
                  <Heading as="h2">{t('payment is succesful')}</Heading>
                  <Text>{t('thank you for purchasing class credits with us')}</Text>
                </>
              ) : (
                <>
                  <Heading as="h2">{t('payment failed')}</Heading>
                  <Text>{t('payment has failed')}</Text>
                </>
              )}
            </HeadingContainer>
            <ButtonContainer>
              <StyledButton onClick={() => navigate('/booking')}>
                {t('book class')}
              </StyledButton>
            </ButtonContainer>
          </>
        )}
      </Container>
    </PageWrapper>
  );
};
